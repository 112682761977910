import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import { NamedLink } from '../../../components';

import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorks.titleLineOne2" />
      </div>

      <div className={css.steps}>
        <div className={css.step}>
          <h2 className={css.stepTitle}>Discover</h2>
          <p>Browse a curated directory of talented creatives.</p>
        </div>

        <div className={css.stepCenter}>
          <h2 className={css.stepTitle}>Connect</h2>
          <p>Inquire about their unique services.</p>
        </div>

        <div className={css.step}>
          <h2 className={css.stepTitle}>Book</h2>
          <p>Hire the right talent for you and your lifestyle.</p>
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
