import { bool, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  SectionHero,
} from '../../components';
import config from '../../config';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import {
  default as facebookImage,
  default as twitterImage,
} from '../../assets/ihtfLinkPreview.png';
import css from './LandingPage.module.css';

import SectionClientsReviews from './SectionClientsReviews/SectionClientsReviews';
import SectionGiftCards from './SectionGiftCards/SectionGiftCards';
import SectionHowItWorks from './SectionHowItWorks/SectionHowItWorks';
import UnauthedLandingPage from './UnauthedLandingPage';
const isDev = process.env.REACT_APP_ENV === 'development';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    pageAssetsData,
    inProgress,
    error,
    isAuthenticated,
  } = props;

  const from = location.state && location.state.from ? location.state.from : null;

  const [transparent, setTransparent] = useState(true);

  const onScroll = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > 50) {
        return setTransparent(false);
      } else if (window.scrollY <= 50) {
        return setTransparent(true);
      }
    }
  };

  if (typeof window !== 'undefined') {
    useEffect(() => {
      window.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    }, [window, onScroll]);
  }

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = 'I Have This Friend';
  const schemaDescription =
    'Connecting you with our most talented Friends across interiors, events, style, art, travel, and more.';
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      {isAuthenticated ? (
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer transparent={transparent} />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.heroContainer}>
              <SectionHero
                rootClassName={css.heroRoot}
                className={css.hero}
                history={history}
                location={location}
              />
            </div>
            <ul className={css.sections}>
              <li className={css.section} style={{ backgroundColor: '#FBF8F1', marginTop: '0' }}>
                <SectionHowItWorks />
              </li>

              <li className={css.section}>
                <SectionClientsReviews />
              </li>

              <li className={css.sectionGiftCards} style={{ backgroundColor: '#FBF8F1' }}>
                <SectionGiftCards />
              </li>
            </ul>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      ) : (
        <UnauthedLandingPage from={from}></UnauthedLandingPage>
      )}
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUserListing, currentUserListingFetched } = state.user;
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    pageAssetsData,
    inProgress,
    error,
    isAuthenticated,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(withRouter, connect(mapStateToProps), injectIntl)(LandingPageComponent);

export default LandingPage;
