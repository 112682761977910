import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';
import css from './SectionClientsReviews.module.css';
import arrowLeft from '../../../assets/icons/arrowLeft.png';
import arrowRight from '../../../assets/icons/arrowRight.png';

import dynamic from 'next/dynamic';

import reviewQuote from '../../../assets/icons/reviewQuote.png';

const Swiper =
  typeof window !== 'undefined'
    ? require('swiper/react').Swiper
    : dynamic(() => import('swiper/react'), {
        ssr: false,
      });
const SwiperSlide =
  typeof window !== 'undefined'
    ? require('swiper/react').SwiperSlide
    : dynamic(() => import('swiper/react'), {
        ssr: false,
      });
const Navigation =
  typeof window !== 'undefined'
    ? require('swiper').Navigation
    : dynamic(() => import('swiper'), {
        ssr: false,
      });

const SectionClientsReviews = props => {
  const { rootClassName, className } = props;
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      require('swiper/css');
      require('swiper/css/bundle');
      require('swiper/css/navigation');
    }
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  const slidesToShow = 1;

  if (typeof window === 'undefined') {
    return null;
  }

  const reviewsData = [
    {
      body:
        'Chloe’s food blew me away. She designed a custom, show-stopping menu for my girlfriend’s 30th birthday. Our friends still rave about the meal.',
      author: 'Chris G. on Chloe P.',
    },
    {
      body:
        'Greta planned a trip to Florence for me and my boyfriend. We couldn’t have been happier with the itinerary — having the local scoop really made the trip. ',
      author: 'Tali H. on Greta Q.',
    },
    {
      body:
        'As someone who’s into lifestyle but not DIY, I Have This Friend is a lifesaver. I’ve now worked with a florist, stylist, interior designer, chef, and photographer.',
      author: 'Nicole H. on I Have This Friend',
    },
    {
      body:
        'Nicole embroidered a gift for me to give to my mom and we’re both obsessed with it. Nicole was so easy to work with and perfectly captured my vision!',
      author: 'Sidney M. on Nicole L.',
    },
    {
      body:
        'Working with Parker for our courthouse wedding was an absolute delight. She was a breeze to work with; we wholeheartedly recommend her.',
      author: 'Renee M. on Parker S.',
    },
    {
      body:
        'Annie came up with super functional design ideas that helped me maximize my tiny office space in a way I never could have envisioned on my own.',
      author: 'Stephanie P. on Annie D.',
    },
  ];

  const reviewsSlides = reviewsData.map((r, index) => {
    return (
      <SwiperSlide key={index}>
        <div className={css.step} key={index}>
          <div className={css.stepContent}>
            <img className={css.quotationMark} src={reviewQuote} />
            <h2 className={css.stepTitle}>{r.body}</h2>
            <p className={css.reviewAuthorName}>– {r.author}</p>
          </div>
        </div>
      </SwiperSlide>
    );
  });

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionClientsReviews.titleLineOne2" />
      </div>

      <div className={css.contentWrapper}>
        <div
          className="custom_prev2"
          style={{ visibility: currentSlideIndex > 0 ? 'visible' : 'hidden' }}
        >
          <img src={arrowLeft} className={css.arrow} />
        </div>
        <div className={css.carouselWrapper}>
          <Swiper
            modules={[Navigation]}
            navigation={{
              nextEl: '.custom_next2',
              prevEl: '.custom_prev2',
            }}
            spaceBetween={30}
            slidesPerView={3}
            onSwiper={swiper => {}}
            onSlideChange={slide => {
              setCurrentSlideIndex(slide.activeIndex);
            }}
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 50,
              },
              // when window width is >= 480px
              750: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              // when window width is >= 640px
              900: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
          >
            {reviewsSlides}
          </Swiper>
        </div>
        <div className="custom_next2">
          <img src={arrowRight} className={css.arrow} />
        </div>
      </div>
    </div>
  );
};

SectionClientsReviews.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionClientsReviews.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionClientsReviews;
