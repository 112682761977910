import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import giftPic from './pic2.jpg';
import { FormattedMessage } from '../../../util/reactIntl';

import { NamedLink } from '../../../components';

import css from './SectionGiftCards.module.css';

const SectionGiftCards = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={css.sectionWrapper}>
      <img src={giftPic} className={css.giftPic} />
      <div className={css.textSection}>
        <h2 className={css.title}>
          <strong>GIVE THE GIFT OF SERVICE</strong>
        </h2>
        <p className={css.description}>Make someone's day that much better.</p>

        <a href="https://ihavethisgiftcard.paperform.co" target="_blank" className={css.shopButton}>
          SHOP GIFT CARDS
        </a>
      </div>
    </div>
  );
};

SectionGiftCards.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionGiftCards.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionGiftCards;
