import React, { useEffect, useState } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import coverVideo from '../../components/SectionHero/promoVideo.mp4';
import fallbackImage from './landingPageFallback.png';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import classNames from 'classnames';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Logo,
  NamedLink,
  SectionHero,
  SectionHowItWorks,
} from '../../components';
import css from './LandingPage.module.css';

export const UnauthedLandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    pageAssetsData,
    inProgress,
    error,
    isAuthenticated,
    from,
  } = props;

  // useEffect(() => {
  //   if (typeof window !== 'undefined' && !isDev) {
  //     window.location.href = 'https://ihavethisfriend.co/';
  //   }
  // }, []);
  // if (!isDev) {
  //   return null;
  // }

  const [transparent, setTransparent] = useState(true);
  const [showVideo, setShowVideo] = useState(false);

  const onScroll = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > 50) {
        return setTransparent(false);
      } else if (window.scrollY <= 50) {
        return setTransparent(true);
      }
    }
  };

  const videoRef = React.useRef(null);

  if (typeof window !== 'undefined') {
    useEffect(() => {
      window.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    }, [window, onScroll]);
  }

  return (
    <LayoutWrapperMain>
      <div>
        <div className={css.backgroundVideo}>
          <video
            className={showVideo ? css.videoActive : css.videoHidden}
            ref={videoRef}
            autoPlay
            loop
            muted
            playsInline
            onPlay={() => {
              setShowVideo(true);
            }}
          >
            <source src={coverVideo} type="video/mp4" />
          </video>
          <img className={showVideo ? css.imageHidden : css.imageActive} src={fallbackImage}></img>
          <div className={css.darkOverlay}></div>
        </div>
        <div className={css.centerSection}>
          <NamedLink className={'logoLinkTransparent'} name="LandingPage" state={{ from }}>
            <Logo
              format="desktop"
              className={'unauthedLogo'}
              alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
              transparent={true}
            />
          </NamedLink>
          <div className={css.buttons}>
            <NamedLink
              name="LoginPage"
              className={classNames(css.loginButton, css.homepageButton)}
              state={{ from }}
            >
              LOG IN
            </NamedLink>
            <NamedLink
              name="SignupPage"
              className={classNames(css.signupButton, css.homepageButton)}
              state={{ from }}
            >
              SIGN UP
            </NamedLink>
          </div>
        </div>
      </div>
    </LayoutWrapperMain>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUserListing, currentUserListingFetched } = state.user;
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    pageAssetsData,
    inProgress,
    error,
    isAuthenticated,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const UnauthedLandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(UnauthedLandingPageComponent);

export default UnauthedLandingPage;
